import '~/styles/global.css'

import { GlobalCanvas } from '@14islands/r3f-scroll-rig'
import { PerformanceMonitor } from '@react-three/drei'
import type { AppProps } from 'next/app'
import { Inter, Space_Mono } from 'next/font/google'
import Head from 'next/head'
import { StrictMode, useRef, useState } from 'react'

export interface SharedPageProps {
  draftMode: boolean
  token: string
}

export const mono = Space_Mono({
  variable: '--font-family-mono',
  subsets: ['latin'],
  weight: ['400'],
})

export const sans = Inter({
  variable: '--font-family-sans',
  subsets: ['latin'],
  weight: ['400', '500', '700', '800'],
})

export default function App({
  Component,
  pageProps,
}: AppProps<SharedPageProps>) {
  const { draftMode, token } = pageProps
  const [dpr, setDpr] = useState(1.5)
  const eventSourceRef = useRef<HTMLDivElement>(null)
  return (
    <StrictMode>
      <style jsx global>
        {`
          html: {
            scrollbar-gutter: stable;
            background-color: #040404;
          }
          body: {
            background-color: #040404;
          }
          :root {
            --font-family-sans: ${sans.style.fontFamily};
            --font-family-mono: ${mono.style.fontFamily};
          }
        `}
      </style>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:image" content="/images/open-graph.png" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/images/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/images/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/images/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/nodeai.webmanifest" />
        <link
          rel="mask-icon"
          href="/images/favicon/safari-pinned-tab.svg"
          color="#4CC054"
        />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff"></meta>
        <link rel="preload" href="/images/light-leak.svg" as="image"></link>
        {/* Twitter Pixel Code */}
        <script
          dangerouslySetInnerHTML={{
            __html: `
              !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
              },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
              a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
              twq('config','opnoc');
            `,
          }}
        />
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16757973957"/>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-16757973957');
            `,
          }}
        />
      </Head>
      <div className="relative z-1" ref={eventSourceRef}>
        <Component {...pageProps} />
      </div>
      <GlobalCanvas
        eventSource={eventSourceRef}
        eventPrefix="client"
        scaleMultiplier={0.01}
        camera={{ fov: 33 }}
        dpr={dpr}
        style={{ pointerEvents: 'none' }}
      >
        <color attach="background" args={['#040404']} />
        <PerformanceMonitor
          onIncline={() => setDpr(2)}
          onDecline={() => setDpr(1)}
        ></PerformanceMonitor>
      </GlobalCanvas>
    </StrictMode>
  )
}
